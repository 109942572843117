<template>
  <qmulus-button
    :loading="notificationStore.notificationUnreadCountIsLoading"
    type="button"
    label=""
    is-inline-action
    :icon="!notificationStore.notificationUnreadCount ? 'pi pi-bell' : NotificationUnreadIcon"
    @click="overlayPanel!.toggle($event);"
  />
  <prm-overlay-panel
    ref="overlayPanel"
    style="
      width: 38.75rem;
      max-height: 47rem;
      overflow: auto;
    "
    :pt="{
      content: {
        style: 'padding: 0;'
      }
    }"
  >
    <div
      style="
        display: flex;
        flex-direction: column;
      "
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: var(--color-101);
          width: 100%;
          height: 5rem;
          padding: var(--gap);
        "
      >
        <div style="font-weight: 700;">
          <span>
            {{ i18n.global.tc('common.notification', 2) }}
          </span>
          <span v-show="!notificationStore.notificationUnreadCountIsLoading">
            {{ ` (${notificationStore.notificationUnreadCount})` }}
          </span>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            gap: var(--gap-union);
          "
        >
          <checkbox-qmulus
            v-model="showOnlyUnreadNotification"
            :disabled="notificationStore.notificationUnreadCountIsLoading"
            :label="i18n.global.t(`${I18N_PREFIX}.showOnlyUnread`)"
          />
          <qmulus-button
            label=""
            is-inline-action
            icon="pi pi-ellipsis-v"
            aria-haspopup="true"
            aria-controls="overflow-menu"
            @click="overflowMenu?.toggle"
          />
          <prm-menu
            ref="overflowMenu"
            :model="overflowMenuItemList"
            :popup="true"
          />
        </div>
      </div>
      <div class="layout-item">
        <prm-skeleton v-if="isLoading" />
        <template v-else>
          <item-notification
            v-for="notification in notificationList?.data.items"
            :key="notification.notificationId"
            :notification="notification"
            @close="overlayPanel!.hide();"
          />
        </template>
      </div>
      <div
        style="
          display: flex;
          justify-content: end;
          align-items: center;
          height: 5rem;
          background-color: var(--color-101);
          width: 100%;
          padding: var(--gap);
        "
      >
        <router-link
          :to="{
            name: ERouteName.NOTIFICATION,
          }"
        >
          <qmulus-button
            :label="i18n.global.t(`${I18N_PREFIX}.seeAll`)"
            @click="overlayPanel!.hide();"
          />
        </router-link>
      </div>
    </div>
  </prm-overlay-panel>
</template>

<script setup lang="ts">
import {
  ref,
  shallowRef,
  watch,
} from 'vue';
import {
  i18n,
} from '@i18n';
import PrmMenu from 'primevue/menu';
import PrmSkeleton from 'primevue/skeleton';
import PrmOverlayPanel from 'primevue/overlaypanel';
import CheckboxQmulus from '@/components/checkbox-qmulus/checkbox-qmulus.vue';
import NotificationUnreadIcon from '@/assets/icon/notification-unread.svg?component';
import QmulusButton from '@/components/button/qmulus-button.vue';
import {
  ERouteName,
} from '@/router/route-name.ts';
import {
  useNotificationStore,
} from '@/store/pinia/notification.ts';
import {
  getInAppNotifications,
  markAllRead,
} from '@/generated/axios/openapi/core.ts';
import ItemNotification from '@/components/item-notification/item-notification.vue';
import {
  useAxiosRequest,
} from '@/composition/use-axios-request';

const I18N_PREFIX = 'components/overlay-panel-notification/overlay-panel-notification';

const notificationStore = useNotificationStore();
notificationStore.loadNotificationUnreadCount();

const showOnlyUnreadNotification = shallowRef(false);

const {
  data: notificationList,
  isLoading,
  execute,
} = useAxiosRequest(() => getInAppNotifications(
  {
    query: {
      page: 1,
      pageSize: 10,
      unseenOnly: showOnlyUnreadNotification.value,
    },
  },
), {
  shouldBeAborted: true,
});

watch(
  showOnlyUnreadNotification,
  execute,
  {
    immediate: true,
    deep: true,
  },
);

const overlayPanel = ref<InstanceType<typeof PrmOverlayPanel> | null>(null);
const overflowMenu = ref<InstanceType<typeof PrmMenu> | null>(null);
const overflowMenuItemList = ref([
  {
    label: i18n.global.t(`${I18N_PREFIX}.markAllAsRead`),
    command: () => markAllRead()
      .then(() => {
        execute();
        notificationStore.loadNotificationUnreadCount(true);
      }),

  },
]);
</script>
