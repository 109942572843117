<template>
  <div
    style="
      display: flex;
      gap: var(--gap);
    "
  >
    <prm-checkbox
      :model-value="modelValue"
      binary
      :disabled="disabled"
      @update:model-value="emit('update:modelValue', $event)"
    >
      <template #icon>
        <span
          :class="icon"
          :style="{
            color: color,
          }"
        />
      </template>
    </prm-checkbox>
    <div v-if="label">
      {{ label }}
    </div>
  </div>
</template>

<script setup lang="ts">
import PrmCheckbox from 'primevue/checkbox';
import {
  computed,
} from 'vue';

const props = defineProps<{
  // this checkbox can have three states.
  // The “null” state is used, when checkbox is neither "true" or "false" and is in intermediate state.
  modelValue: boolean | null,
  label?: string,
  disabled?: boolean,
}>();

// eslint-disable-next-line func-call-spacing, no-spaced-func
const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>();

const icon = computed(() => {
  if (props.modelValue === null) {
    return 'pi pi-minus';
  }
  if (props.modelValue) {
    return 'pi pi-check';
  }
  return '';
});

const color = computed(() => {
  if (props.modelValue === null) {
    return 'var(--color-224)';
  }
  return 'var(--color-100)';
});
</script>
