<template>
  <div
    class="app-tile"
    :class="{
      'app-tile_disabled': !userHasPermission || appStore.isNavigating,
    }"
  >
    <router-link
      :to="{
        name: appListMap[app.appId].route,
      }"
      class="app-tile__link"
      :style="`border-color: ${appTileColorMap[app.appGroupSemantic]};`"
    >
      <div class="app-tile__content">
        <component
          :is="appListMap[app.appId].icon"
          class="app-tile__content-icon"
        />
        <div>
          <div
            class="typo-font-3"
            style="
              color: var(--color-224);
            "
          >
            {{ app.displayName }}
          </div>
          <div
            class="typo-small"
            style="
              padding-top: var(--gap-union);
              color: var(--color-104);
            "
          >
            {{ i18n.global.t(`api.AppGroupCommercial.${app.appGroupCommercial}`) }}
          </div>
        </div>
      </div>
    </router-link>

    <prm-button
      v-if="userHasPermission"
      type="button"
      text
      severity="secondary"
      class="app-tile__pin-button"
      :disabled="customUserStore.customPinnedAppMapIsLoading"
      @hover.capture.stop
      @click.capture.stop="customUserStore.toggleCustomPinnedApp(app.appId)"
    >
      <template #icon>
        <pin-on-icon
          v-if="isAppPinned"
          style="color: var(--icon-icon-action)"
          class="app-tile__pin-icon"
        />
        <pin-off-icon
          v-else
          style="color: var(--icon-icon-action)"
          class="app-tile__pin-icon"
        />
      </template>
    </prm-button>
  </div>
</template>

<script lang="ts" setup>
import PrmButton from 'primevue/button';
import {
  i18n,
} from '@i18n';
import {
  computed,
} from 'vue';
import {
  TApp,
} from '@/types/app-type.ts';
import {
  appListMap,
} from '@/types/app-util.ts';
import PinOnIcon from '@/assets/icon/pin-on.svg?component';
import PinOffIcon from '@/assets/icon/pin-off.svg?component';
import {
  useAppStore,
} from '@/store/pinia/app.ts';
import {
  useCustomUserStorageStore,
} from '@/store/pinia/custom-user-storage.ts';
import {
  appTileColorMap,
} from '@/utils/app-tile.ts';

const appStore = useAppStore();
const customUserStore = useCustomUserStorageStore();
customUserStore.loadCustomPinnedAppMap();

const props = defineProps<{
  app: TApp,
}>();

// TODO use customUserStorage
const isAppPinned = computed(() => customUserStore.customPinnedAppMap?.[props.app.appId]);
const userHasPermission = computed(() => !!appStore.user!.appPermissions?.[props.app.appId]);
</script>

<style lang="scss" scoped>
@use '@/styles/util/color.scss';

$horz-gap: var(--gap-union);

.app-tile{
  position: relative;
  width: 100%;
  height: 3.875rem;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid var(--color-103);
  display: flex;
  justify-content: space-between;

  &_disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 50%;
  }

  &__link {
    display: flex;
    padding: var(--gap);
    justify-content: space-between;
    border-left: 12px solid;
    flex-grow: 1;
  }

  &__pin-button {
    padding: var(--gap);
    color: var(--color-232);
  }

  &__content {
    height: 100%;
    display: flex;
    align-items: center;
    gap: $horz-gap;
  }

  &__content-icon {
    width: 1.875rem;
    height: 1.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-224);
  }

  &__pin-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}
</style>
